import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import block_45route_45global from "/usr/src/app/middleware/block-route.global.js";
import cart_45cookie_45validation_45global from "/usr/src/app/middleware/cart-cookie-validation.global.js";
import check_45tenant_45global from "/usr/src/app/middleware/check-tenant.global.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  block_45route_45global,
  cart_45cookie_45validation_45global,
  check_45tenant_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-user": () => import("/usr/src/app/middleware/auth-user.js"),
  guest: () => import("/usr/src/app/middleware/guest.js"),
  auth: () => import("/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}
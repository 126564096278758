import i18next from "i18next";
import { useAuthStore } from "./auth";
import { useLocaleStore } from "./locales";
import { defineStore } from "pinia";

// App unsaved state
export const useMainStore = defineStore("main", {
  state: () => ({
    country: null,
    locale: "en",
    categories: [],
    products: [],
    features: [],
    currentSite: null,
    currentSiteSettings: null,
    maintenance: false,
    sites: [],
    isRtl: false,
    enableShop: true,
    translationData: [],
    isLoading: true,
    isEasyPayResultLoading: false
  }),
  actions: {
    async fetchInitial() {
      await Promise.all([
        this.loadCurrentSite(),
        this.loadMenu(),
        this.loadFeatures(),
        useLocaleStore().fetchLocales(),
        this.fetchUser()
      ]);
    },

    async fetchUser() {
      const { $cookies } = useNuxtApp();

      const token = $cookies.get("AuthTkn");

      if (token) {
        await useAuthStore.fetch();
      }
    },

    async loadCurrentSite() {
      const route = useRoute();

      if (!route.params.country) {
        return;
      }
      const nuxtApp = useNuxtApp();

      const [country, locale] = route.params.country.split("-");

      try {
        const [siteResponse, jsonResponse] = await Promise.all([
          useAPI(`api/sites/${country}`),
          useAPI(`api/country-wise-locale-content/${country}/${locale}`),
        ]);

        if (jsonResponse.data._rawValue) {
          const obj = {
            lng: locale,
            fallbackLng: "en",
            lazy: true,
            resources: jsonResponse.data._rawValue,
          };
          i18next.init(obj);
          this.translationData = obj;
          console.log(this.translationData)
        } else {
          this.translationData = null;
        }
        this.isLoading = false;

        this.currentSite = siteResponse.data._rawValue;

        this.currentSiteSettings = siteResponse.data._rawValue && siteResponse.data._rawValue.settings || {};
        this.country = country;
        this.locale = locale;
        this.isRtl = ["he", "ar", "fa"].includes(locale);

        nuxtApp.$cookies.set("CountryLocale", `${country}/${locale}`, {
          path: "/",
        });

        const countryLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
        countryLocale.value = `${country}/${locale}`

        let token = nuxtApp.$cookies.get("AuthTkn");

        if (token) {
          const expires_at = nuxtApp.$cookies.get("expires_at");

          nuxtApp.$cookies.set("AuthTkn", token, {
            expires: new Date(expires_at),
            secure: process.env.NODE_ENV === "production",
            path: `/${country}-${locale}`,
          });
        }

      } catch (error) {
        if (import.meta.browser) {
          const cLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
          cLocale.value = ''
        }
        this.isLoading = false;
        // console.log(cLocale)
        nuxtApp.$cookies.remove("CountryLocale");
        if (error.response && (error.response.status === 503 || error.response.status === 500)) {
          this.maintenance = true;
        } else {
          console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
        }
      }
    },

    async loadMenu() {
      try {
        const { data, error } = await useAPI("api/front/menu/products?type_id=1")
        // useAPI("/api/front/menu/categories/v2?type_id=2"),
        this.products = data._rawValue ? data._rawValue : [];
      } catch (error) {
        console.error(error);
      }
    },
    async loadFeatures() {
      try {
        const { data } = await useAPI("api/get-features")
        this.features = data._rawValue.data;
      } catch (error) {
        console.error(error);
      }
    },
    async loadTranslationJsonData(route) {
      const nuxtApp = useNuxtApp();

      const [country, locale] = route.split("-");

      try {
        const jsonResponse = await useAPI(
          `api/country-wise-locale-content/${country}/${locale}`
        );
        if (jsonResponse.data) {
          const obj = {
            lng: locale,
            fallbackLng: "en",
            lazy: true,
            resources: jsonResponse.data._rawValue,
          };
          i18next.init(obj);
          this.translationData = obj;
        } else {
          this.translationData = null;
        }
        this.isLoading = false;
      } catch (error) {
        // const cLocale = useCookie('CountryLocale')
        // cLocale.value = ''
        if (import.meta.browser) {
          const cLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
          cLocale.value = ''
        }
        this.isLoading = false;
         nuxtApp.$cookies.remove("CountryLocale");
        if (error.response && (error.response.status === 503 || error.response.status === 500)) {
          this.maintenance = true;
        } else {
          console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
        }
      }
    },
    easyResultLoading(result) {
      this.isEasyPayResultLoading = result
    }

  },
});

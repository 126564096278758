<template>
  <!-- <client-only>: prevent Mismatching childNodes vs. VNodes -->
  <client-only>
    <component
      :is="type"
      v-bind="$attrs"
      :to="to"
      :class="classList"
      class="px-4 py-1.5 border text-center font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 hover:no-underline transition"
    >
      <slot />
    </component>
  </client-only>
</template>

<style scoped>
[disabled] {
  @apply bg-opacity-30 text-opacity-50 cursor-not-allowed !important;
}
.bg-primary {
  background: linear-gradient(150deg, #e94e47, #b41d16) !important;
}
</style>

<script setup>
const colors = {
  gradient:
    'border-transparent text-white bg-gradient from-bg-primary-dark-1 to-bg-primary-dark-2 hover:bg-primary-600 focus:ring-primary-500 hover:text-gray-200',
  primary:
    'border-transparent text-white bg-primary hover:bg-primary-600 focus:ring-primary-500 hover:text-gray-200',
  secondary:
    'border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500 hover:text-primary-700',
  black:
    'border-transparent text-white bg-gray-900 hover:bg-gray-700 focus:ring-gray-500',
  white:
    'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-50',
  link: 'text-[#807e7e] bg-white hover:text-dark focus:ring-indigo-50 border-transparent',
}

const sizes = {
  base: 'text-base',
  xs: 'text-xs',
  sm: 'text-sm',
  xl: 'text-xl',
}

const props = defineProps({
  color: {
    required: false,
    type: String,
    default: 'primary',
  },
  size: {
    required: false,
    type: String,
    default: 'base',
  },
  block: {
    required: false,
    type: Boolean,
    default: false,
  },
  rounded: {
    required: false,
    type: Boolean,
    default: true,
  },
  to: {
    type: String,
    default: null,
  },
})

const type = computed(() => {
  if (props.to) {
    return 'nuxt-link'
  } else {
    return 'button'
  }
})
const colorsClass = computed(() => {
  return colors[props.color]
})

const sizeClass = computed(() => {
  return sizes[props.size]
})

const roundedClass = computed(() => {
  return props.rounded ? 'rounded-[10px]' : ''
})

const displayClass = computed(() => {
  return props.block ? 'block w-full' : 'inline-block'
})

const classList = computed(() => {
  const classList = `${displayClass.value} ${colorsClass.value} ${sizeClass.value} ${roundedClass.value}`
  return classList
})
</script>

import { useAuthStore } from "../store/auth";
import { useMainStore } from "../store/index";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const store = useMainStore();
    const { $cookies } = useNuxtApp();
    const { token } = useAuth();

    let headers = {};
    if (store.country) {
        headers = {
            "X-Tenant": store.country,
            "X-Locale": store.locale,
        };
    }

    // const token = $cookies.get("AuthTkn");

    if (token.value) {
        headers = {
            ...headers,
            Authorization: token.value,
        };
    }

    const api = $fetch.create({
        baseURL: config.public.baseURL,
        credentials: "include",
        onRequest({ request, options }) {
            // You can add global headers here
            let headersData = {}
            options.headers.forEach((v, k) => {
                headersData = {
                    ...headersData,
                    [k]: v
                }
            })

            options.headers = {
                ...options.headers,
                ...headers,
                ...headersData
            };
        },
        onResponse({ request, response, options }) {
            // Handle the response here
        },
        onResponseError({ request, response, options }) {
            // Handle the error here
            console.error("Fetch error: ", response.status, response.statusText);
            if (response && response.status === 401) {
                useAuthStore().logout();
            }

            return Promise.reject(response);
        },
    });

    // nuxtApp.provide("api", fetch);
    return {
        provide: {
            api
        }
    }
});
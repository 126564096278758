import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { useMainStore } from "../store/index";

export default defineNuxtPlugin((nuxtApp) => {
    const store = useMainStore();

    nuxtApp.vueApp.use(Vue3Toastify, {
        autoClose: 3000,
        theme: "dark",
        rtl: store.isRtl,
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
    });

    return {
        provide: { toast },
    };
});
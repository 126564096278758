<template>
  <div class="flex items-center justify-start w-full h-[100vh] bg-[#e9ecef]">
    <div class="container flex flex-col items-center justify-center">
      <h1 class="mb-3 text-4xl font-medium uppaercase font-ff-condensed">
        {{ $t('common.404_title') }}
      </h1>
      <p class="mb-5 text-xl font-light lead font-ff-condensed">
        {{ $t('common.404_description') }}
      </p>
      <Btn
        v-text="$t('common.home_page')"
        @click="() => gotoHome()"
        color="gradient"
        class="w-auto px-2 py-3 text-xl font-semibold leading-5 text-white uppercase rounded-lg font-ff-condensed bg-primary"
      ></Btn>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
})
function gotoHome() {
  if (import.meta.browser) {
    window.location.href = '/'
  }
}
</script>

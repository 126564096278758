import { useMainStore } from "~/store"

export default defineNuxtPlugin((app) => {
    const store = useMainStore()

    // Hash url
    const metricoolHash = {
        // Argentina
        ar: {
            hash: '491b33c507f30d90aaa1238cdebce026',
        },
        // Ecuador
        ec: {
            hash: '3d90bb86474caccdfdb216edf9a0d76b',
        },
        // Peru
        pe: {
            hash: '13cdb9b64209afacb786611cfb2be622',
        },
        // Colombia
        co: {
            hash: 'e4b4a792a7cd4fd8e1c8c1a3eb7defbd',
        },
        // Panamá
        pa: {
            hash: '2847b47790a61f284eb6ae4d71f838c8',
        },
        // USA
        us: {
            hash: '9dd3f2d28da0cfd8d668fefbab012bec',
        },
        // Costa Rica
        cr: {
            hash: '4b04da8061bcc78983485453749cc8fe',
        },
        // Guatemala
        gt: {
            hash: 'fe14fb1dfe31b66158c4e9f3d79fe2d7',
        },
        // El Salvador
        sv: {
            hash: '9bc6f7669a38f3c9349b3002c2572908',
        },
        // Chile
        cl: {
            hash: '2ae9582d9b577c313a588f938c38f8f2',
        },
        // Republica Dominicana
        do: {
            hash: '786f9b60fdb8674e7f8b24f6cf9f4fbc',
        },
        // Bolivia
        bo: {
            hash: '78c5c7858ed7096760b77c1cfeb36092',
        },
        // Uruguay
        uy: {
            hash: 'a39a39f40471a727c11cd165cc01deb1',
        },
    }
    if (metricoolHash[store.country] && import.meta.browser) {
        const hash = metricoolHash[store.country].hash
        const script = document.createElement('script')
        script.innerHTML = `function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash: '${hash}'})});`
        script.async = true
        document.getElementsByTagName('head')[0].appendChild(script)
    }

})
